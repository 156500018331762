<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M9.9974 20.6571L17.9864 21.0758C19.4572 21.1529 20.7119 20.0231 20.789 18.5524L21.1379 11.8948M9.9974 20.6571L9.76895 20.6452C9.06267 20.6082 8.37062 20.8532 7.84503 21.3265L4.46203 24.3725L5.2994 8.39446C5.37648 6.92371 6.63124 5.79393 8.10198 5.87101L18.754 6.42926C20.2248 6.50634 21.3546 7.76109 21.2775 9.23183L21.1379 11.8948M9.9974 20.6571L9.92761 21.9886C9.85054 23.4594 10.9803 24.7141 12.4511 24.7912L20.6686 25.2219C21.3748 25.2589 22.0375 25.575 22.5107 26.1005L25.5568 29.4835L26.3244 14.837C26.4015 13.3662 25.2717 12.1115 23.8009 12.0344L21.1379 11.8948M9.15436 11.2668L17.1434 11.6855M8.94501 15.2613L14.271 15.5405"
		stroke="url(#paint0_linear_813_8044)"
		stroke-width="1.17073"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_813_8044"
			x1="5.43896"
			y1="5.73145"
			x2="27.9135"
			y2="11.0353"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#BEB2F7" />
			<stop offset="0.958333" stop-color="#8D79EE" />
		</linearGradient>
	</defs>
</svg>
