<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect
		x="0.652278"
		y="0.652278"
		width="15.6547"
		height="15.6547"
		rx="7.82734"
		fill="#8D79EE"
		fill-opacity="0.15"
		stroke="#8D79EE"
		stroke-width="1.30456"
	/>
</svg>
