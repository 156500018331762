<script lang="ts">
	import DoubleCaretIcon from '$lib/icons/double-caret-icon.svelte';
	import { fade, fly } from 'svelte/transition';
	import { createEventDispatcher, getContext, onMount } from 'svelte';
	import AnimatedArrow from '$components/atoms/AnimatedArrow.svelte';
	const TOGGLE_TIME = 10000; // 10 seconds
	const dispatch = createEventDispatcher();
	let t = getContext('page-contents-landing') as Record<string, string>;

	const TEXTS = {
		offers: t.offerings_our_amazing_offers,
		benefits: t.offerings_your_amazing_benefits
	};
	export let variant: 'offers' | 'benefits' = 'offers';
</script>

<div class="max-w-634px bg-linear-purple-100 min-h-81px rounded-4 relative flex items-center px-5">
	{#if variant === 'offers'}
		<button
			class="w-70px h-70px rounded-4 duration-900 absolute top-1/2 z-10 grid -translate-y-1/2 transform place-items-center bg-white shadow-md transition-all hover:shadow-lg"
			class:offer={variant === 'offers'}
			aria-label="Go to benefits"
			on:click={() => {
				variant = 'benefits';
				dispatch('change', { variant });
			}}
			in:fly|global={{ x: -500, duration: 400 }}
		>
			<!-- <DoubleCaretIcon direction="right" /> -->
			<div class="w-60px h-60px flex-center relative right--1.5 transform">
				<AnimatedArrow />
			</div>
		</button>
		<h2
			class="karla-bold mr-75px lh-2.4rem text-left text-white"
			in:fade|global={{ duration: 100 }}
			style="font-size: clamp(34px, 3vw + 1rem, 48px);"
		>
			{TEXTS[variant]}
		</h2>
	{:else}
		<button
			class="w-70px h-70px rounded-4 duration-900 absolute top-1/2 z-10 grid -translate-y-1/2 transform place-items-center bg-white shadow-md transition-all hover:shadow-lg"
			class:benefit={variant === 'benefits'}
			aria-label="Go to offers"
			on:click={() => {
				variant = 'offers';
				dispatch('change', { variant });
			}}
			in:fly|global={{ x: 500, duration: 400 }}
		>
			<div class="w-60px h-60px flex-center relative left--1.5 rotate-180">
				<AnimatedArrow />
			</div>
		</button>
		<h2
			class="karla-bold ml-75px lh-2.4rem text-left text-white"
			in:fade|global={{ duration: 100 }}
			style="font-size: clamp(34px, 3vw + 1rem, 48px);"
		>
			{TEXTS[variant]}
		</h2>
	{/if}
</div>

<style type="postcss">
	.offer {
		@apply right-4px;
	}
	.benefit {
		@apply left-4px;
	}
	.offer,
	.benefit {
		transition: all 900ms ease;
	}
</style>
