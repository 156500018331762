<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2490"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2490)">
		<path
			d="M45.3333 134.166H-8.33334C-23.153 134.166 -35.1667 122.153 -35.1667 107.333V26.833L-15.0417 40.2497L5.08332 26.833L25.2083 40.2497L45.3333 26.833V80.4997M45.3333 134.166V80.4997M45.3333 134.166V134.166C60.153 134.166 72.1666 122.153 72.1666 107.333V80.4997H45.3333M-8.33334 60.3747H18.5M-8.33334 87.208H18.5M-8.33334 114.041H18.5"
			stroke="url(#paint0_linear_827_2490)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2490"
			x1="-35.1667"
			y1="26.833"
			x2="72.7859"
			y2="43.7202"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
