<script lang="ts">
	import { randomClipId } from '$utils/randomClipId';
	let randomId = randomClipId();
</script>

<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect
		x="0.652278"
		y="0.652278"
		width="15.6547"
		height="15.6547"
		rx="7.82734"
		stroke="#8D79EE"
		stroke-width="1.30456"
	/>
	<g clip-path={`url(#${randomId})`}>
		<path
			d="M17.0844 3.15446C17.4279 2.79242 17.4279 2.21218 17.0844 1.85014C16.7317 1.47838 16.1523 1.47838 15.7996 1.85014L8.12368 9.94062L6.45494 7.59548C6.15945 7.18021 5.5873 7.08859 5.18357 7.40775C4.79046 7.7185 4.71689 8.29411 5.00532 8.69946L7.30072 11.9253C7.45451 12.1414 7.69407 12.2819 7.96049 12.3018C8.22716 12.3218 8.48495 12.2183 8.6679 12.0254L17.0844 3.15446Z"
			fill="#1E1E1E"
			stroke="white"
			stroke-width="0.652278"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<clipPath id={randomId}>
			<rect width="13.0456" height="11.2666" fill="white" transform="translate(4.5625 1.30469)" />
		</clipPath>
	</defs>
</svg>
