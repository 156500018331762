<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M21.0684 13.8928L21.0804 13.8934M13.0794 13.4741L13.0913 13.4748M28.8481 18.306C28.5013 24.9244 22.8549 30.0084 16.2365 29.6615C9.61819 29.3147 4.53415 23.6683 4.881 17.05C5.22785 10.4316 10.8743 5.34759 17.4926 5.69444C24.1109 6.04129 29.195 11.6877 28.8481 18.306ZM10.207 17.3291C10.1024 19.3263 12.2548 24.1122 16.5157 24.3355C20.7765 24.5588 23.3825 20.6899 23.5221 18.0269L10.207 17.3291Z"
		stroke="url(#paint0_linear_813_8058)"
		stroke-width="1.17073"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_813_8058"
			x1="5.50903"
			y1="5.06641"
			x2="30.6744"
			y2="11.297"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#BEB2F7" />
			<stop offset="0.958333" stop-color="#8D79EE" />
		</linearGradient>
	</defs>
</svg>
