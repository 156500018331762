<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2372"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2372)">
		<path
			d="M31.5634 102.561C35.6163 101.332 40.3721 100.625 45.4584 100.625C60.278 100.625 72.2917 106.632 72.2917 114.041C72.2917 121.451 60.278 127.458 45.4584 127.458C40.3721 127.458 35.6163 126.75 31.5634 125.521M31.5634 102.561C24.2929 97.3363 12.1953 93.9163 -1.49995 93.9163C-15.1952 93.9163 -27.2928 97.3363 -34.5633 102.561M31.5634 102.561C36.094 105.817 38.7501 109.774 38.7501 114.041C38.7501 118.308 36.094 122.265 31.5634 125.521M31.5634 125.521C24.2929 130.746 12.1953 134.166 -1.49995 134.166C-15.1952 134.166 -27.2928 130.746 -34.5633 125.521M-34.5633 102.561C-38.6162 101.332 -43.372 100.625 -48.4583 100.625C-63.2779 100.625 -75.2916 106.632 -75.2916 114.041C-75.2916 121.451 -63.2779 127.458 -48.4583 127.458C-43.372 127.458 -38.6162 126.75 -34.5633 125.521M-34.5633 102.561C-39.0939 105.817 -41.75 109.774 -41.75 114.041C-41.75 118.308 -39.0939 122.265 -34.5633 125.521M18.625 46.958C18.625 58.0727 9.61478 67.083 -1.49995 67.083C-12.6147 67.083 -21.625 58.0727 -21.625 46.958C-21.625 35.8433 -12.6147 26.833 -1.49995 26.833C9.61478 26.833 18.625 35.8433 18.625 46.958ZM58.8751 67.083C58.8751 74.4928 52.8682 80.4997 45.4584 80.4997C38.0486 80.4997 32.0417 74.4928 32.0417 67.083C32.0417 59.6732 38.0486 53.6663 45.4584 53.6663C52.8682 53.6663 58.8751 59.6732 58.8751 67.083ZM-35.0416 67.083C-35.0416 74.4928 -41.0485 80.4997 -48.4583 80.4997C-55.8681 80.4997 -61.875 74.4928 -61.875 67.083C-61.875 59.6732 -55.8681 53.6663 -48.4583 53.6663C-41.0485 53.6663 -35.0416 59.6732 -35.0416 67.083Z"
			stroke="url(#paint0_linear_839_2372)"
			stroke-width="3"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2372"
			x1="-75.2916"
			y1="26.833"
			x2="70.0512"
			y2="58.0953"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
