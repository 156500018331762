<script lang="ts">
	import ArrowIcon from '$lib/icons/arrow-icon.svelte';
	import { onDestroy } from 'svelte';
	export let totalNumberOfPages: number;
	export let infiniteNavigation = false;
	export let navigationType: 'dots' | 'numbers' = 'numbers';
	export let autoplayInterval = 5000;
	export let disableAutoplay = true;
	let autoplay = false;
	$: currentPageIndex = 0;

	let windowsWidth: number;
	let offset = 0;
	let durationMs = 500;
	let timingFunction = 'ease-in-out';

	function loadPage(pageIndex: number) {
		if (pageIndex < 0) {
			pageIndex = totalNumberOfPages - 1;
		} else if (pageIndex >= totalNumberOfPages) {
			pageIndex = 0;
		}
		currentPageIndex = pageIndex % totalNumberOfPages;
		offset = pageIndex * windowsWidth;
	}

	$: {
		totalNumberOfPages > 0 && loadPage(0);
	}

	function jumpToPage(pageIndex: number) {
		if (!infiniteNavigation && (pageIndex < 0 || pageIndex >= totalNumberOfPages)) return;
		loadPage(pageIndex);
	}

	function nextPage() {
		jumpToPage(currentPageIndex + 1);
	}

	function previousPage() {
		jumpToPage(currentPageIndex - 1);
	}

	$: pageIndexToShow = {
		start: Math.max(0, currentPageIndex - 2),
		end: Math.min(totalNumberOfPages - 1, Math.max(0, currentPageIndex - 2) + 3)
	};

	const handleAutoplayInterval = setInterval(() => {
		if (autoplay && !disableAutoplay) {
			nextPage();
		}
	}, autoplayInterval);

	let lastInteractionTime = Date.now();

	const handleNextButton = () => {
		lastInteractionTime = Date.now();
		autoplay = false;
		nextPage();
	};

	const handlePreviousButton = () => {
		lastInteractionTime = Date.now();
		autoplay = false;
		previousPage();
	};

	const handleJumpToPage = (pageIndex: number) => {
		lastInteractionTime = Date.now();
		autoplay = false;
		jumpToPage(pageIndex);
	};

	const checkUserInteraction = setInterval(() => {
		if (Date.now() - lastInteractionTime >= 5000) {
			autoplay = true;
		}
	}, 1000);

	onDestroy(() => {
		clearInterval(checkUserInteraction);
		clearInterval(handleAutoplayInterval);
	});
</script>

<div>
	<div class="sc-carousel__pages-window" bind:offsetWidth={windowsWidth}>
		<div
			class="sc-carousel__pages-container"
			style="
        transform: translateX(-{offset}px);
        translate-origin: left;
        transition-duration: {durationMs}ms;
        transition-timing-function: {timingFunction};
        "
		>
			<slot {currentPageIndex} />
		</div>
	</div>
	<div class="flex-center mt-10">
		<button
			class="w-38px h-38px border-primary flex-center mx-1 cursor-pointer rounded-full border-2"
			on:click={handlePreviousButton}
			aria-label="Previous Page"
		>
			<ArrowIcon />
		</button>
		{#each Array(totalNumberOfPages) as _, index (index)}
			{#if navigationType !== 'dots' && index === pageIndexToShow.start && index > 0}
				<div class="text-grey-400">...</div>
			{/if}
			{#if navigationType === 'dots' || (index >= pageIndexToShow.start && index <= pageIndexToShow.end)}
				<button
					class="px3 text-grey-400"
					class:!text-grey-800={currentPageIndex === index}
					on:click={() => handleJumpToPage(index)}
					disabled={currentPageIndex === index}
					aria-label={`Page ${index + 1}`}
				>
					{#if navigationType === 'dots'}
						<div
							class="border-1px border-primary h-2.5 w-2.5 rounded-full transition-all duration-300"
							class:bg-primary={currentPageIndex === index}
							class:px-5={currentPageIndex === index}
						/>
					{:else}
						{index + 1}
					{/if}
				</button>
			{/if}
			{#if navigationType !== 'dots' && index === pageIndexToShow.end && index < totalNumberOfPages - 1}
				<div class="text-grey-400">...</div>
			{/if}
		{/each}
		<button
			class="w-38px h-38px min-w-38px min-h-38px border-primary flex-center mx-1 cursor-pointer rounded-full border-2"
			on:click={handleNextButton}
			aria-label="Next Page"
		>
			<ArrowIcon direction="right" />
		</button>
	</div>
</div>

<style>
	.sc-carousel__pages-container {
		width: 100%;
		display: flex;
		transition-property: transform;
	}

	.sc-carousel__pages-window {
		flex: 1;
		display: flex;
		overflow-x: clip;
		box-sizing: border-box;
		position: relative;
	}
</style>
