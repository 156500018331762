<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M20.1782 27.0221L21.5116 27.0221C22.9843 27.0221 24.1782 25.8282 24.1782 24.3555L24.1782 15.0221C24.1782 13.5494 22.9843 12.3555 21.5116 12.3555L20.1782 12.3555M20.1782 27.0221L20.1782 28.3555M20.1782 27.0221L12.1782 27.0221M12.1782 27.0221L10.8449 27.0221C9.37213 27.0221 8.17822 25.8282 8.17822 24.3555L8.17822 15.0221C8.17822 13.5494 9.37213 12.3555 10.8449 12.3555L12.1782 12.3555M12.1782 27.0221L12.1782 28.3555M20.1782 12.3555L20.1782 7.02214C20.1782 5.54938 18.9843 4.35547 17.5116 4.35547L14.8449 4.35547C13.3721 4.35547 12.1782 5.54938 12.1782 7.02214L12.1782 12.3555M20.1782 12.3555L12.1782 12.3555M20.1782 16.3555L20.1782 23.0221M16.1782 16.3555L16.1782 23.0221M12.1782 16.3555L12.1782 23.0221"
		stroke="url(#paint0_linear_813_8030)"
		stroke-width="1.17073"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_813_8030"
			x1="8.17822"
			y1="4.35547"
			x2="25.4939"
			y2="6.57969"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#BEB2F7" />
			<stop offset="0.958333" stop-color="#8D79EE" />
		</linearGradient>
	</defs>
</svg>
