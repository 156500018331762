<script lang="ts">
	import { randomClipId } from '$utils/randomClipId';
	let randomId = randomClipId();
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
	<g clip-path={`url(#${randomId})`}>
		<path
			d="M23.9866 12.2242C23.9866 11.2409 23.9049 10.5234 23.7282 9.7793H12.2383V14.2173H18.9826C18.8467 15.3202 18.1124 16.9811 16.4807 18.0972L16.4578 18.2458L20.0907 20.996L20.3424 21.0205C22.654 18.9344 23.9866 15.8649 23.9866 12.2242Z"
			fill="#4285F4"
		/>
		<path
			d="M12.2372 23.9178C15.5413 23.9178 18.3152 22.8548 20.3413 21.0211L16.4796 18.0978C15.4462 18.8021 14.0592 19.2937 12.2372 19.2937C9.00096 19.2937 6.25427 17.2076 5.27515 14.3242L5.13164 14.3361L1.35409 17.193L1.30469 17.3271C3.31711 21.2337 7.45077 23.9178 12.2372 23.9178Z"
			fill="#34A853"
		/>
		<path
			d="M5.2758 14.3238C5.01745 13.5797 4.86794 12.7824 4.86794 11.9586C4.86794 11.1347 5.01745 10.3375 5.26221 9.59342L5.25536 9.43494L1.43048 6.53223L1.30533 6.59039C0.47592 8.21149 0 10.0319 0 11.9586C0 13.8853 0.47592 15.7056 1.30533 17.3267L5.2758 14.3238Z"
			fill="#FBBC05"
		/>
		<path
			d="M12.2372 4.62403C14.5351 4.62403 16.0852 5.59402 16.9691 6.40461L20.4228 3.10928C18.3017 1.1826 15.5413 0 12.2372 0C7.45077 0 3.3171 2.68406 1.30469 6.59057L5.26156 9.59359C6.25427 6.7102 9.00096 4.62403 12.2372 4.62403Z"
			fill="#EB4335"
		/>
	</g>
	<defs>
		<clipPath id={randomId}>
			<rect width="24" height="24" fill="white" />
		</clipPath>
	</defs>
</svg>
