<script lang="ts">
	import { randomClipId } from '$utils/randomClipId';

	/**
	 * generates random id for clip-path
	 *
	 */
	let randomId = randomClipId();
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
	<g clip-path={`url(#${randomId})`}>
		<path
			d="M23.9993 9.17204H14.8363L12.006 0L9.16693 9.17204L0.00390625 9.16289L7.42462 14.8371L4.58527 24L12.006 18.3349L19.4177 24L16.5873 14.8371L23.9993 9.17204Z"
			fill="#00B67A"
		/>
		<path d="M17.2221 16.9114L16.5853 14.8369L12.0039 18.3347L17.2221 16.9114Z" fill="#005128" />
	</g>
	<defs>
		<clipPath id={randomId}>
			<rect width="24" height="24" fill="white" />
		</clipPath>
	</defs>
</svg>
