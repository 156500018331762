<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2363"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2363)">
		<path
			d="M12.5 98.125H12.5588M12.5001 78.5V71.9583C19.7259 71.9583 25.5835 66.1007 25.5835 58.875C25.5835 51.6493 19.7259 45.7917 12.5001 45.7917C7.65745 45.7917 3.42928 48.4227 1.16711 52.3333M58.2917 65.4167C58.2917 83.5009 34.877 112.517 21.5341 127.583C16.6792 133.065 8.32091 133.065 3.46598 127.583C-9.87693 112.517 -33.2916 83.5009 -33.2916 65.4167C-33.2916 40.1266 -12.79 19.625 12.5 19.625C37.7901 19.625 58.2917 40.1266 58.2917 65.4167Z"
			stroke="url(#paint0_linear_839_2363)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2363"
			x1="-33.2916"
			y1="19.625"
			x2="59.6976"
			y2="30.9389"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
