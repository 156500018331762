<script lang="ts">
	import ArticleCard from './ArticleCarouselCard.svelte';
	import Carousel from '../Carousel.svelte';
	import type { Article } from '$types/articles.types';
	import CtaBanner from './CtaBanner.svelte';

	export let articles: Article[];
</script>

<div class="md:max-w-1120px max-w-545px mx-a w-full">
	<Carousel
		infiniteNavigation
		navigationType="dots"
		totalNumberOfPages={articles.length + 1}
		autoplayInterval={10000}
	>
		{#each articles as data, index}
			{#if index === 1}
				<CtaBanner variant="lg" />
			{/if}
			<ArticleCard article={data} />
		{/each}
	</Carousel>
</div>
