<script lang="ts">
	import { SOCIALS_CONFIG } from '$lib/constants/socials';
	import isDesktop from '$store/isDesktop';
	import { getContext } from 'svelte';

	let t = getContext('page-contents-landing') as Record<string, string>;
	const CONTACTS = [
		{
			name: t.phone,
			value: SOCIALS_CONFIG.phone,
			link: 'tel:+34687153866'
		},
		{
			name: 'WhatsApp',
			value: '+(34) 871 15 36 69',
			link: SOCIALS_CONFIG.whatsapp
		},
		{
			name: t.email,
			value: 'info@89transfers.com',
			link: `mailto:${SOCIALS_CONFIG.email}`
		},
		{
			name: t.address,
			value: "Carrer d'Eusebi Estada, 29, 8ºA, 07004 Palma, Balearic Islands",
			link: 'https://maps.app.goo.gl/QTU18ptP6XjXUZ4N8'
		}
	];
</script>

<div
	class="z-1 min-w-300px max-w-389px min-h-389px rounded-5px md:max-w-445px mt-10 bg-white p-5 md:mt-0"
	class:md-position={$isDesktop}
	style="box-shadow: 0px 0px 0px 0px rgba(38, 38, 52, 0.10), 4px 4px 13px 0px rgba(38, 38, 52, 0.10), 16px 17px 23px 0px rgba(38, 38, 52, 0.09), 37px 38px 32px 0px rgba(38, 38, 52, 0.05), 65px 67px 37px 0px rgba(38, 38, 52, 0.01), 102px 105px 41px 0px rgba(38, 38, 52, 0.00);"
>
	{#each CONTACTS as contact, index}
		<div
			class="flex flex-col items-start justify-start gap-2"
			class:mb-5={index !== CONTACTS.length - 1}
		>
			<div class="rubik-regular-sm text-grey-500">{contact.name}</div>
			<a href={contact.link} class="text-grey-800 hover:text-primary rubik-regular text-18px">
				{contact.value}
			</a>
		</div>
	{/each}
	<button
		class="rounded-5px rubik-regular text-18px flex-center cta bg-linear-purple-100
     py-10px px-25px mx-auto mt-6 text-white"
		on:click={() => window.open(`mailto:${SOCIALS_CONFIG.email}`)}
	>
		{t.contact_us_write_an_email}</button
	>
</div>

<style type="postcss">
	.md-position {
		@apply top-35 absolute;
		right: max(calc((100vw - 1120px) / 2), 20px);
	}
</style>
