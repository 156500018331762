<script context="module" lang="ts">
	import { writable } from 'svelte/store';

	export const activeFaq = writable(0);

	let id = 1;
</script>

<script lang="ts">
	import { page } from '$app/stores';
	import CollapseCaret from '$lib/icons/collapse-caret.svelte';
	import { darkMode } from '$store/darkMode';
	import type { SvelteComponent } from 'svelte';
	import { onMount } from 'svelte';
	import { backOut } from 'svelte/easing';
	import { fade, slide } from 'svelte/transition';

	import snakeCase from 'lodash/snakeCase';
	export let question: string;
	export let answer: string;
	export let icon: typeof SvelteComponent<any>;

	const getSnakeTitleForFaq = (ques: string) => {
		if (ques) {
			let formattedValue = ques.replaceAll(/[?.,]/g, '');
			return snakeCase(formattedValue);
		}
		return '';
	};

	const faqHash = getSnakeTitleForFaq(question);

	const addHash = (hash: string = '') => {
		if (typeof window !== 'undefined') {
			window.history.pushState({}, '', '#' + hash);
		}
	};

	const componentId = id++;

	$: active = $activeFaq === componentId;

	function toggleCollapse() {
		if ($activeFaq === componentId) {
			$activeFaq = 0;
			addHash();
			return;
		}
		$activeFaq = componentId;
		addHash(faqHash);
	}

	onMount(() => {
		if ($page?.url?.hash) {
			const currentHash = '#' + faqHash;
			if (currentHash == $page.url.hash) {
				$activeFaq = componentId;
				setTimeout(() => {
					const faqElement = document.querySelector(
						`[data-location="${currentHash}"] .active-desc`
					);
					faqElement?.scrollIntoView({ block: 'center' });
				}, 0);
			}
		}
	});
</script>

<div
	class="rounded-4 my4 dark:bg-grey-700 min-h-169px md:min-h-160px rounded-15px container relative relative flex h-auto w-full max-w-[550px] flex-shrink-0 flex-col overflow-hidden bg-white focus:outline-none"
	data-location={'#' + faqHash}
>
	<button
		class="pl-23.75 pr-15 min-h-139px md:min-h-156px w-full overflow-visible p-5 py-5 text-left"
		on:click={toggleCollapse}
		aria-expanded={active}
		aria-label={question}
		tabindex="0"
		on:keypress={(e) => e.key === 'Space' && toggleCollapse()}
	>
		<span class="absolute left-0 top-0 overflow-visible">
			<svelte:component this={icon} />
		</span>
		<h3
			class="karla-medium lh-[100%] sm:lh-unset relative"
			style="font-size: clamp(22px, 1.8vw + 5px, 26px);"
		>
			{question}
			<div class="absolute right--7 top-1/2 -translate-y-1/2 transform">
				<CollapseCaret isDarkMode={$darkMode} isOpen={active} />
			</div>
		</h3>
	</button>
	{#if active}
		<div
			class="dark:bg-grey-700 pl-23.75 pr-15 active-desc relative w-full overflow-hidden bg-white pb-5 text-left"
			in:slide|global={{ duration: 400, easing: backOut }}
			out:slide|global={{ duration: 400, delay: 100, easing: backOut }}
		>
			{#if answer}
				<p
					class="rubik-regular ls--0.05px lh-[20px] text-[16px] dark:text-white"
					out:fade|global={{ duration: 100 }}
				>
					{@html answer}
				</p>
			{/if}
		</div>
	{/if}
</div>

<style>
	.container {
		box-shadow: 0px 0px 0px 0px rgba(38, 38, 52, 0.1), 4px 4px 13px 0px rgba(38, 38, 52, 0.1),
			16px 17px 23px 0px rgba(38, 38, 52, 0.09), 37px 38px 32px 0px rgba(38, 38, 52, 0.05),
			65px 67px 37px 0px rgba(38, 38, 52, 0.01), 102px 105px 41px 0px rgba(38, 38, 52, 0);
	}
</style>
