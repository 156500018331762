<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2399"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2399)">
		<path
			d="M46.2916 32.708H59.3749C66.6007 32.708 72.4583 38.5656 72.4583 45.7913V58.8747M46.2916 32.708H-45.2917M46.2916 32.708C46.2916 47.1595 58.0068 58.8747 72.4583 58.8747M72.4583 58.8747V98.1247M72.4583 98.1247V111.208C72.4583 118.434 66.6007 124.291 59.375 124.291H46.2916M72.4583 98.1247C58.0068 98.1247 46.2916 109.84 46.2916 124.291M46.2916 124.291H-45.2917M-71.4584 98.1247V111.208C-71.4584 118.434 -65.6008 124.291 -58.375 124.291H-45.2917M-71.4584 98.1247V58.8747M-71.4584 98.1247C-57.0069 98.1247 -45.2917 109.84 -45.2917 124.291M-71.4584 58.8747V45.7913C-71.4584 38.5656 -65.6008 32.708 -58.375 32.708H-45.2917M-71.4584 58.8747C-57.0069 58.8747 -45.2917 47.1595 -45.2917 32.708M20.125 78.4997C20.125 92.9511 11.3385 104.666 0.499952 104.666C-10.3386 104.666 -19.125 92.9511 -19.125 78.4997C-19.125 64.0482 -10.3386 52.333 0.499952 52.333C11.3385 52.333 20.125 64.0482 20.125 78.4997Z"
			stroke="url(#paint0_linear_839_2399)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2399"
			x1="-71.4584"
			y1="32.708"
			x2="68.3805"
			y2="67.0834"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
