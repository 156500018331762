<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2353"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2353)">
		<path
			d="M-35.8334 58.875V117.75C-35.8334 124.976 -29.9758 130.833 -22.75 130.833H55.75C62.9757 130.833 68.8333 124.976 68.8333 117.75V58.875M-35.8334 58.875V45.7917C-35.8334 38.5659 -29.9758 32.7083 -22.75 32.7083H-9.66671M-35.8334 58.875H68.8333M68.8333 58.875V45.7917C68.8333 38.5659 62.9757 32.7083 55.75 32.7083H42.6666M-9.66671 19.625V32.7083M-9.66671 32.7083H42.6666M42.6666 19.625V32.7083"
			stroke="url(#paint0_linear_839_2353)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2353"
			x1="-35.8334"
			y1="19.625"
			x2="69.7251"
			y2="35.1664"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
