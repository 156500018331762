<script lang="ts">
	import { randomClipId } from '$utils/randomClipId';
	let randomId = randomClipId();
</script>

<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path={`url(#${randomId})`}>
		<path
			d="M22.3466 17.0898C24.352 15.6641 25.6856 13.3456 25.755 10.6966C25.8721 6.22488 22.342 2.5049 17.8702 2.3878C13.3985 2.27071 9.67851 5.80084 9.56142 10.2726C9.49205 12.9215 10.7026 15.3067 12.6305 16.8354M22.3466 17.0898C20.9668 18.0709 19.2689 18.6291 17.4462 18.5814C15.6234 18.5337 13.9571 17.8873 12.6305 16.8354M22.3466 17.0898L21.965 31.6635L17.2342 26.6782L12.2489 31.409L12.6305 16.8354"
			stroke="#8D79EE"
			stroke-width="1.33333"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<clipPath id={randomId}>
			<rect
				width="32"
				height="32"
				fill="white"
				transform="translate(1.20312 0.517578) rotate(1.5)"
			/>
		</clipPath>
	</defs>
</svg>
