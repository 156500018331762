<script lang="ts">
	import CloseIcon from '$lib/icons/close-icon.svelte';
	import { darkMode } from '$store/darkMode';
	import { fade, scale } from 'svelte/transition';
	export let isOpen = false;
	export let title = 'Modal title';
	export let classname = '';
</script>

{#if isOpen}
	<div
		class={'z-301 bg-grey-800 fixed left-0 top-0 h-full w-full bg-opacity-25 '}
		class:open={true}
		class:!hidden={!isOpen}
		in:fade|global={{ duration: 400 }}
		out:fade|global={{ duration: 400 }}
	/>
{/if}
{#if isOpen}
	<div
		class={`bg-grey-100 dark:bg-grey-800 z-1001 pb-15 dark:bg-grey-800 rounded-10 max-w-470px mx-a fixed left-1/2 top-1/2  block w-[calc(100%-40px)]  -translate-x-1/2 -translate-y-1/2 transform p-4 md:w-full ${classname}`}
		class:open={true}
		class:!hidden={!isOpen}
		in:scale|global={{ duration: 400 }}
		out:scale|global={{ duration: 400 }}
	>
		<div class="relative w-full px-4">
			<h2 class="karla-medium-xl text-grey-800 leading-7.5 py-4 text-center dark:text-white">
				{title}
			</h2>
			<button
				class="flex-center gap-xs rubik-regular-sm absolute right-2 top-1/2 -translate-y-1/2 pl-4 dark:text-white"
				on:click={() => (isOpen = false)}
				aria-label="Close"
			>
				<CloseIcon color={$darkMode ? '#ffff' : '#000'} />
			</button>
		</div>
		<slot>Add your content here</slot>
	</div>
{/if}

<style lang="postcss">
	.open {
		animation: fadeIn 0.3s ease-in-out;
	}

	:global(.disable-scroll) {
		@apply fixed left-0 top-0 w-full overflow-hidden;
	}
</style>
