<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2517"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2517)">
		<path
			d="M-39.8333 71.9587V104.667C-39.8333 111.893 -33.9757 117.75 -26.75 117.75V117.75M-39.8333 71.9587V39.2503C-39.8333 32.0246 -33.9757 26.167 -26.75 26.167H51.75C58.9757 26.167 64.8333 32.0246 64.8333 39.2503V71.9587M-39.8333 71.9587H64.8333M64.8333 71.9587V104.667C64.8333 111.893 58.9757 117.75 51.75 117.75V117.75M32.125 117.75H-7.125M32.125 117.75V127.563C32.125 132.982 36.5182 137.375 41.9375 137.375V137.375C47.3568 137.375 51.75 132.982 51.75 127.563V117.75M32.125 117.75H51.75M-7.125 117.75V127.563C-7.125 132.982 -11.5182 137.375 -16.9375 137.375V137.375C-22.3568 137.375 -26.75 132.982 -26.75 127.563V117.75M-7.125 117.75H-26.75M-13.6667 91.5837H-20.2083M38.6667 91.5837H45.2083"
			stroke="url(#paint0_linear_827_2517)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2517"
			x1="-39.8333"
			y1="26.167"
			x2="65.7251"
			y2="41.7084"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
