<script lang="ts">
	import Carousel from '../Carousel.svelte';
	import PopularDestinationCard from './PopularDestinationCard.svelte';
	import { chunkArray } from '$utils/chunkArray';
	import isDesktop from '$store/isDesktop';
	import type { PopularDestination } from '$types/popularDestinations.types';
	export let data: PopularDestination[];
	$: chunkArrayData = chunkArray(data, $isDesktop ? 12 : 4);
</script>

<Carousel totalNumberOfPages={chunkArrayData.length} infiniteNavigation let:currentPageIndex>
	{#each chunkArrayData as arrData, i}
		<div
			class="relative grid w-full flex-shrink-0 grid-cols-1 place-items-center gap-5 pb-5 delay-500 md:grid-cols-2 md:px-10 lg:grid-cols-4"
		>
			{#each arrData as pdata}
				<PopularDestinationCard
					data={pdata}
					isHidden={i !== currentPageIndex &&
						i !== currentPageIndex - 1 &&
						i !== currentPageIndex + 1}
				/>
			{/each}
		</div>
	{/each}
</Carousel>
