<script lang="ts">
	import { LOCALE } from '$lib/constants/locale';

	const locales = LOCALE;
</script>

<div style="display: none;visibility: hidden;">
	{#each locales as locale}
		<a href={`/${locale}`}>{locale} version</a>
	{/each}
</div>
