<script lang="ts">
	import GoogleIcon from '$lib/icons/google-icon.svelte';
	import TripadvisorIcon from '$lib/icons/tripadvisor-icon.svelte';
	import TrustpilotIcon from '$lib/icons/trustpilot-icon.svelte';
	import Carousel from '$components/landing/Carousel.svelte';
	import ReviewCard from '$components/landing/reviews/ReviewCard.svelte';
	import isDesktop from '$store/isDesktop';
	import Rating from './Rating.svelte';
	import { chunkArray } from '$utils/chunkArray';
	import type { Review } from '$types/review';
	import Modal from '$components/atoms/modal/Modal.svelte';
	import Avatar from './Avatar.svelte';
	import StarIcon from '$lib/icons/star-icon.svelte';
	import { getRelativeTime } from '$utils/relativeTime';
	import { getContext } from 'svelte';
	export let data: Review[];
	let active: 'all' | 'google' | 'tripadvisor' | 'trustpilot' = 'all';
	let t = getContext('page-contents-landing') as Record<string, string>;

	$: filteredReviews = data.filter((review) => {
		if (active === 'all') return true;
		if (active === 'google') return review.platform === 'google';
		if (active === 'tripadvisor') return review.platform === 'tripadvisor';
		if (active === 'trustpilot') return review.platform === 'trustpilot';
	});
	let isModalOpen = false;
	let isReviewModalOpen = false;
	const handlePlatformClick = (platform: 'all' | 'google' | 'tripadvisor' | 'trustpilot') => {
		active = platform;
	};
	let activeReview: Review | null = null;

	const PLATFORMS: {
		name: 'all' | 'google' | 'tripadvisor' | 'trustpilot';
		icon: any;
		url: string;
	}[] = [
		{
			name: 'all',
			icon: null,
			url: ''
		},
		{
			name: 'google',
			icon: GoogleIcon,
			url: 'https://search.google.com/local/writereview?placeid=ChIJe191gb6TlxIRm4kJbhhSBTY'
		},
		{
			name: 'tripadvisor',
			icon: TripadvisorIcon,
			url: 'https://www.tripadvisor.com/UserReviewEdit-g187463-d15144654-89Transfers-Palma_de_Mallorca_Majorca_Balearic_Islands.html'
		},
		{
			name: 'trustpilot',
			icon: TrustpilotIcon,
			url: 'https://www.trustpilot.com/evaluate/89transfers.com'
		}
	];

	$: chunkArrayData = chunkArray(filteredReviews, $isDesktop ? 6 : 2);

	const handleModalOpen = () => {
		isModalOpen = true;
	};

	const handleReviewModalOpen = () => {
		isReviewModalOpen = true;
	};
</script>

<div class="relative mx-auto max-w-[1120px]">
	<div class="px-5">
		<h3
			class="karla-bold z-1
        max-w-735px relative my-10 w-full md:w-[min(700px,67vw)]"
			style="font-size: clamp(34px, 3vw + 1rem, 48px);"
		>
			{t.reviews_just_works}
		</h3>
		<Rating className="flex-col-end absolute right-5 top-0 hidden gap-1.5 md:flex" />
		<div class="flex-between mb-10 flex flex-row items-center md:hidden">
			<Rating className="flex-col-start gap-1.5" />
			<button
				class="rounded-5px karla-medium-base flex-center bg-linear-purple-100 cta gap-2.5 px-5
            py-2.5 text-white"
				on:click={handleModalOpen}
			>
				{t.add_reviews}
			</button>
		</div>
		<div class="flex-between hide-scrollbar mb-10 flex flex-row items-center overflow-x-auto">
			<div class=" flex flex-row items-center justify-start gap-3">
				{#each PLATFORMS as platform}
					<button
						class="rounded-15px karla-medium-base flex-center gap-2.5 bg-white px-5 py-2.5 capitalize"
						class:border-grey-800={active === platform.name}
						class:border-2={active === platform.name}
						on:click={() => handlePlatformClick(platform.name)}
					>
						{#if platform.icon}
							<svelte:component this={platform.icon} />
						{/if}
						{platform.name}
					</button>
				{/each}
			</div>
			<button
				class="rounded-5px karla-medium-base flex-center bg-linear-purple-100 cta hidden gap-2.5 px-5 py-2.5 text-white md:block"
				on:click={handleModalOpen}
			>
				{t.add_reviews}
			</button>
		</div>
	</div>
	<Carousel totalNumberOfPages={chunkArrayData.length} infiniteNavigation let:currentPageIndex>
		{#each chunkArrayData as reviewChunk, i}
			<div
				class="grid w-full flex-shrink-0 grid-cols-1 content-start items-start gap-5 p-5 md:grid-cols-2 lg:grid-cols-3"
			>
				{#each reviewChunk as review}
					{#if !(currentPageIndex !== i && currentPageIndex !== i - 1 && currentPageIndex !== i + 1)}
						<ReviewCard
							{review}
							on:open={(e) => {
								activeReview = e.detail.review;
								handleReviewModalOpen();
							}}
						/>
					{/if}
				{/each}
			</div>
		{/each}
	</Carousel>
</div>

<Modal title={t.add_reviews} bind:isOpen={isModalOpen} on:close={() => (isModalOpen = false)}>
	<div class="mb-5 flex flex-row flex-wrap-reverse items-center justify-center gap-3">
		{#each PLATFORMS as platform}
			{#if platform.name !== 'all'}
				<button
					class="rounded-15px karla-medium-base flex-center flex-shrink-0 gap-2.5 bg-white px-5 py-2.5 capitalize"
					on:click={() => {
						window.open(platform.url, '_blank');
						isModalOpen = false;
					}}
				>
					{#if platform.icon}
						<svelte:component this={platform.icon} />
					{/if}
					{platform.name}
				</button>
			{/if}
		{/each}
	</div>
</Modal>
{#if activeReview}
	<Modal
		title={t.review}
		bind:isOpen={isReviewModalOpen}
		on:close={() => {
			activeReview = null;
		}}
		classname="!rounded-18px"
	>
		<div class="flex flex-row flex-nowrap items-center gap-x-2">
			{#if activeReview.avatarUrl}
				<img src={activeReview.avatarUrl} alt="user icon" class="h-10 w-10 rounded-full" />
			{:else}
				<div
					class="bg-grey-200 dark:text-grey-800 flex h-10 w-10 items-center justify-center rounded-full p-1 text-xl"
				>
					<Avatar username={activeReview.name} />
				</div>
			{/if}
			<div class="rubik-base flex flex-col leading-5 dark:text-white">
				<p>{activeReview.name}</p>
				<div class="flex flex-row items-center gap-x-1">
					{#each Array(5) as _, index (index)}
						{#if index < activeReview.rating}
							<StarIcon color="#FFD339" />
						{:else if activeReview.rating % 1 !== 0 && index === Math.floor(activeReview.rating + 1)}
							<StarIcon percentage={(activeReview.rating % 1) * 100} color="#FFD339" />
						{:else}
							<StarIcon color="transparent" />
						{/if}
					{/each}
				</div>
			</div>
		</div>
		<div class="rubik-regular mt-5 text-base dark:text-white">
			{activeReview.review}
		</div>
		<div
			class="absolute bottom-4 flex w-[calc(100%-40px)] flex-row flex-nowrap items-center justify-between text-xs text-gray-500"
		>
			<p>{getRelativeTime(activeReview.date ? new Date(activeReview.date) : new Date())}</p>
			<a
				href={activeReview.url}
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Go to author"
				class="w-56px h-36px bg-grey-100 flex-center rounded-15px"
			>
				{activeReview.platform}
			</a>
		</div>
	</Modal>
{/if}
