<svg width="33" height="68" viewBox="0 0 33 68" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M17.5143 1.05252C17.0739 0.719235 16.4467 0.806056 16.1134 1.24644C15.7801 1.68683 15.8669 2.31401 16.3073 2.6473L17.5143 1.05252ZM0.918273 60.1615C0.589599 60.6053 0.682957 61.2316 1.12679 61.5602L8.35954 66.9163C8.80338 67.245 9.42963 67.1516 9.7583 66.7078C10.087 66.2639 9.99362 65.6377 9.54978 65.309L3.12067 60.5481L7.88161 54.119C8.21028 53.6751 8.11692 53.0489 7.67309 52.7202C7.22925 52.3915 6.603 52.4849 6.27433 52.9287L0.918273 60.1615ZM16.3073 2.6473C23.8154 8.32941 27.9839 14.5182 29.727 20.6116C31.4702 26.7056 30.8125 32.7902 28.52 38.3106C23.9149 49.3993 12.7586 58.1003 1.57447 59.7675L1.86936 61.7457C13.7685 59.9718 25.5011 50.7945 30.367 39.0776C32.8099 33.1952 33.5332 26.6453 31.6499 20.0616C29.7664 13.4774 25.3008 6.94545 17.5143 1.05252L16.3073 2.6473Z"
		fill="url(#paint0_linear_817_14383)"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_817_14383"
			x1="-8"
			y1="6.29688"
			x2="28.5527"
			y2="3.51708"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
