<script lang="ts">
	import { goto } from '$app/navigation';
	import type { LANGUAGE } from '$lib/constants/locale';
	import type { Article } from '$types/articles.types';
	import { LOCALIZELINK } from '$utils/link';
	import { pastDate } from '$utils/pastDate';
	import { getContext } from 'svelte';
	export let article: Article;
	let lang = getContext('lang') as LANGUAGE;
	const formatDate = (date: Date | null) => {
		if (!date) {
			return '';
		}
		return date.toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric'
		});
	};
	const handleArticleClick = () => {
		goto(LOCALIZELINK(lang, `${article.category.value}/${article.pageID}`));
	};
</script>

<div
	class="md:max-w-1120px max-w-545px mx-a min-h-420px w-full flex-shrink-0 bg-[#F2F3FE] px-2.5 py-2.5"
>
	<div class={`grid-cols-0 grid  h-full  place-content-center gap-5 md:grid-cols-2 `}>
		<div class="img-container max-w-540px h-full w-full overflow-clip transition-all duration-500">
			<img
				src={article?.featuredImage?.url}
				class="h-full w-full scale-110 object-cover transition-all duration-500"
				alt={article?.featuredImage?.alt || ''}
				loading="lazy"
			/>
		</div>
		<div class="w-full">
			<div class="text-grey-400">
				Traveling | {article.readTime} min | {formatDate(pastDate(article.date, article.createdAt))}
			</div>
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<h3
				class="karla-medium !font-600 text-26px text-grey-800 max-w-455px my-10 cursor-pointer hover:underline"
				on:click={handleArticleClick}
			>
				{article.title}
			</h3>
			<div class="block w-full bg-white px-1.5 py-2">
				<div class="rubik-regular font-4.5 mb-2">Table of Content</div>
				<ul class="text-grey-800 text-16px font-400 leading-30px w-full list-inside list-disc px-2">
					{#each article.tableOfContents as item, i}
						{#if i < 5}
							<li class="w-full" title={item.label}>
								{item.label}
							</li>
						{/if}
					{/each}
				</ul>
			</div>
		</div>
	</div>
</div>

<style type="postcss">
	.img-container:hover img {
		transform: scale(1);
		transition: all 0.5s ease-in-out;
	}

	.sub-heading {
		@apply karla-bold z-1 max-w-550px text-grey-800 relative w-full;
		font-size: clamp(34px, 3vw + 1rem, 48px);
	}
</style>
