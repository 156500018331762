<script lang="ts">
	import { goto } from '$app/navigation';
	import ChecklistCheckedIcon from '$lib/icons/checklist-checked-icon.svelte';
	import ChecklistUncheckedIcon from '$lib/icons/checklist-unchecked-icon.svelte';
	import CurvedArrow from '$lib/icons/curved-arrow.svelte';
	import { getContext } from 'svelte';
	import { LINK_BOOKING, LOCALIZELINK } from '$utils/link';
	import type { LANGUAGE } from '$lib/constants/locale';
	let t = getContext('page-contents-landing') as Record<string, string>;

	const ITEMS: {
		title: string;
		checked: boolean;
	}[] = [
		{
			title: t.banner_2_buy_airline_tickets,
			checked: true
		},
		{
			title: t.banner_2_check_hotels,
			checked: true
		},
		{
			title: t.banner_2_book_airport_transfer,
			checked: false
		}
	];
	let lang = getContext('lang') as LANGUAGE;
	const handleBooking = () => {
		goto(LOCALIZELINK(lang, LINK_BOOKING));
	};
</script>

<div class="rounded-5 max-w-90 h-104 w-full bg-white drop-shadow-2xl">
	<div class="mt-5 flex justify-around px-3">
		{#each Array(4) as _}
			<span
				class="w-3.75 md:w-5.5 md:h-5.5 h-3.75 bg-purple inline-block rounded-full"
				style="box-shadow: 0.65228px 2.60911px 2.60911px 0px rgba(0, 0, 0, 0.15) inset;"
			/>
		{/each}
	</div>
	<h3
		class="karla-medium text-grey-800 mx-a flex-center my-3 px-3 text-2xl"
		class:text-xl={lang === 'es'}
	>
		{t.banner_2_mallorca_travel_checklist}
	</h3>
	<div
		class="h-1.5px w-full"
		style="background: linear-gradient(99deg, #D7DBFF 0%, #C2C8FF 89.1%);"
	/>
	<div class="mt-10 flex flex-col gap-10 px-5" class:gap-6={lang === 'es'}>
		{#each ITEMS as item}
			<div
				class="text-18px karla-medium flex items-center justify-start gap-5"
				class:line-through={item.checked}
				class:text-grey-500={item.checked}
			>
				{#if item.checked}
					<ChecklistCheckedIcon />
				{:else}
					<ChecklistUncheckedIcon />
				{/if}
				{item.title}
			</div>
		{/each}
	</div>
	<div class="bottom-15 right-17% absolute">
		<CurvedArrow />
	</div>
	<button
		class="rounded-5px karla-medium-base flex-center bg-linear-purple-100 w-136px h-46px absolute bottom-10 left-1/2 -translate-x-1/2 transform gap-2.5 text-white"
		on:click={handleBooking}
	>
		{t.book_transfer}
	</button>
</div>
