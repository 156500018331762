<script lang="ts">
	export const direction: 'left' | 'right' = 'left';
</script>

<div class="scrolldown-container relative flex h-[50px] w-[50px] items-center justify-center">
	<div
		class="scrolldown-btn absolute bottom-auto left-1/2 right-auto top-1/2 h-[50px] w-[50px] -translate-x-1/2 -translate-y-1/2 transform text-center {direction ===
		'left'
			? 'rotate-180'
			: ''}"
	>
		<svg
			version="1.1"
			id="arrow"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			width="80px"
			height="50px"
			viewBox="0 0 80 50"
			enable-background="new 0 0 80 50"
			xml:space="preserve"
		>
			<path
				class="first-path rounded-full"
				fill="#8B79E9"
				d="M79.182,25.248c0,0.397-0.154,0.752-0.463,1.06L57.234,47.793c-0.305,0.306-0.656,0.458-1.057,0.458 s-0.752-0.152-1.059-0.458l-2.305-2.305c-0.309-0.309-0.461-0.663-0.461-1.06c0-0.398,0.152-0.752,0.461-1.061l18.119-18.119                  L52.813,6.878c-0.309-0.306-0.461-0.657-0.461-1.057c0-0.402,0.152-0.753,0.461-1.059l2.305-2.306                  c0.307-0.308,0.658-0.461,1.059-0.461s0.752,0.153,1.057,0.461l21.485,21.485C79.027,24.496,79.182,24.85,79.182,25.248z"
			/>
			<path
				class="second-path rounded-full"
				fill="#B3A7F0"
				d="M58.25,25.248c0,0.397-0.154,0.752-0.463,1.06L36.303,47.793c-0.304,0.306-0.655,0.458-1.057,0.458                  c-0.4,0-0.752-0.152-1.058-0.458l-2.305-2.305c-0.308-0.309-0.461-0.663-0.461-1.06c0-0.398,0.153-0.752,0.461-1.061l18.12-18.119                  L31.883,6.878c-0.308-0.306-0.461-0.657-0.461-1.057c0-0.402,0.153-0.753,0.461-1.059l2.305-2.306                  c0.306-0.308,0.657-0.461,1.058-0.461c0.401,0,0.753,0.153,1.057,0.461l21.484,21.485C58.096,24.496,58.25,24.85,58.25,25.248z"
			/>
			<path
				class="third-path rounded-full"
				fill="#D9D3F8"
				d="M37.318,25.248c0,0.397-0.154,0.752-0.463,1.06L15.371,47.793c-0.304,0.306-0.655,0.458-1.057,0.458                  c-0.4,0-0.752-0.152-1.058-0.458l-2.305-2.305c-0.308-0.309-0.461-0.663-0.461-1.06c0-0.398,0.153-0.752,0.461-1.061l18.12-18.119                  L10.951,6.878c-0.308-0.306-0.461-0.657-0.461-1.057c0-0.402,0.153-0.753,0.461-1.059l2.305-2.306                  c0.306-0.308,0.657-0.461,1.058-0.461c0.401,0,0.753,0.153,1.057,0.461l21.484,21.485C37.164,24.496,37.318,24.85,37.318,25.248z"
			/>
		</svg>
	</div>
</div>

<style>
	.scrolldown-container .scrolldown-btn svg {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	.scrolldown-container .scrolldown-btn svg path.first-path {
		animation: scrollanim 1s ease-in-out infinite;
		animation-delay: 0.8s;
	}
	.scrolldown-container .scrolldown-btn svg path.second-path {
		animation: scrollanim2 1s ease-in-out infinite;
	}
	.scrolldown-container .scrolldown-btn svg path.third-path {
		animation: scrollanim3 1s ease-in-out infinite;
		animation-delay: 0.4s;
	}

	@-webkit-keyframes scrollanim {
		0% {
			-webkit-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0, 0);
			opacity: 0.8;
		}
	}
	@-moz-keyframes scrollanim {
		0% {
			-moz-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-moz-transform: translate(0, 0);
			opacity: 0.8;
		}
	}
	@keyframes scrollanim {
		0% {
			-webkit-transform: translate(-40px, 0);
			-moz-transform: translate(-40px, 0);
			-ms-transform: translate(-40px, 0);
			-o-transform: translate(-40px, 0);
			transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0, 0);
			-moz-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			-o-transform: translate(0, 0);
			transform: translate(0, 0);
			opacity: 0.8;
		}
	}
	@-webkit-keyframes scrollanim2 {
		0% {
			-webkit-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0px, 0);
			opacity: 0.6;
		}
	}
	@-moz-keyframes scrollanim2 {
		0% {
			-moz-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-moz-transform: translate(0px, 0);
			opacity: 0.6;
		}
	}
	@keyframes scrollanim2 {
		0% {
			-webkit-transform: translate(-40px, 0);
			-moz-transform: translate(-40px, 0);
			-ms-transform: translate(-40px, 0);
			-o-transform: translate(-40px, 0);
			transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0px, 0);
			-moz-transform: translate(0px, 0);
			-ms-transform: translate(0px, 0);
			-o-transform: translate(0px, 0);
			transform: translate(0px, 0);
			opacity: 0.6;
		}
	}
	@-webkit-keyframes scrollanim3 {
		0% {
			-webkit-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0px, 0);
			opacity: 0.4;
		}
	}
	@-moz-keyframes scrollanim3 {
		0% {
			-moz-transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-moz-transform: translate(0px, 0);
			opacity: 0.4;
		}
	}
	@keyframes scrollanim3 {
		0% {
			-webkit-transform: translate(-40px, 0);
			-moz-transform: translate(-40px, 0);
			-ms-transform: translate(-40px, 0);
			-o-transform: translate(-40px, 0);
			transform: translate(-40px, 0);
			opacity: 0;
		}
		60% {
			-webkit-transform: translate(0px, 0);
			-moz-transform: translate(0px, 0);
			-ms-transform: translate(0px, 0);
			-o-transform: translate(0px, 0);
			transform: translate(0px, 0);
			opacity: 0.4;
		}
	}
</style>
