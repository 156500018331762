<script lang="ts">
	import { randomClipId } from '$utils/randomClipId';

	let randomId = randomClipId();
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
	<g clip-path={`url(#${randomId})`}>
		<path
			d="M12.0001 24.0002C18.6276 24.0002 24.0002 18.6276 24.0002 12.0001C24.0002 5.37262 18.6276 0 12.0001 0C5.37262 0 0 5.37262 0 12.0001C0 18.6276 5.37262 24.0002 12.0001 24.0002Z"
			fill="#34E0A1"
		/>
		<path
			d="M19.4136 9.65884L20.8617 8.08345H17.6504C16.0428 6.98525 14.1013 6.3457 12.0018 6.3457C9.90473 6.3457 7.96862 6.98669 6.36371 8.08345H3.14453L4.59248 9.65884C3.70488 10.4687 3.14849 11.6353 3.14849 12.9304C3.14849 15.3747 5.13015 17.3563 7.57441 17.3563C8.73561 17.3563 9.79331 16.9085 10.583 16.1764L12.0016 17.721L13.4202 16.1778C14.2099 16.9099 15.2665 17.3563 16.4275 17.3563C18.8718 17.3563 20.8561 15.3747 20.8561 12.9304C20.8574 11.6338 20.301 10.4674 19.4134 9.65884H19.4136ZM7.57567 15.9256C5.92108 15.9256 4.58042 14.585 4.58042 12.9304C4.58042 11.2758 5.92126 9.93515 7.57567 9.93515C9.23026 9.93515 10.5709 11.276 10.5709 12.9304C10.5709 14.5848 9.23008 15.9256 7.57567 15.9256ZM12.003 12.8433C12.003 10.8722 10.5697 9.18021 8.67783 8.4575C9.70097 8.02981 10.8231 7.79257 12.0018 7.79257C13.1802 7.79257 14.3038 8.02981 15.327 8.4575C13.4366 9.18165 12.0032 10.8722 12.0032 12.8433H12.003ZM16.4289 15.9256C14.7744 15.9256 13.4337 14.585 13.4337 12.9304C13.4337 11.2758 14.7745 9.93515 16.4289 9.93515C18.0835 9.93515 19.4242 11.276 19.4242 12.9304C19.4242 14.5848 18.0834 15.9256 16.4289 15.9256ZM16.4289 11.359C15.5613 11.359 14.8588 12.0615 14.8588 12.9291C14.8588 13.7966 15.5613 14.4991 16.4289 14.4991C17.2964 14.4991 17.9989 13.7966 17.9989 12.9291C17.9989 12.063 17.2964 11.359 16.4289 11.359ZM9.14584 12.9304C9.14584 13.7978 8.44328 14.5004 7.57567 14.5004C6.70823 14.5004 6.00568 13.7978 6.00568 12.9304C6.00568 12.0628 6.70823 11.3602 7.57567 11.3602C8.4431 11.359 9.14584 12.0628 9.14584 12.9304Z"
			fill="#0F0F0F"
		/>
	</g>
	<defs>
		<clipPath id={randomId}>
			<rect width="24" height="24" fill="white" />
		</clipPath>
	</defs>
</svg>
