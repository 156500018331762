<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2472"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="157"
	>
		<rect width="75" height="157" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2472)">
		<path
			d="M-39 109.138C-50.9681 112.113 -58.5 116.437 -58.5 121.25C-58.5 130.225 -32.3087 137.5 0 137.5C32.3087 137.5 58.5 130.225 58.5 121.25C58.5 116.437 50.9681 112.113 39 109.138M35.75 58.4167C35.75 72.4311 19.7381 94.3739 9.14603 107.356C4.35572 113.228 -4.35572 113.228 -9.14603 107.356C-19.7382 94.3739 -35.75 72.4311 -35.75 58.4167C-35.75 37.4759 -19.7442 20.5 0 20.5C19.7442 20.5 35.75 37.4759 35.75 58.4167ZM9.75 56.25C9.75 61.6348 5.38478 66 0 66C-5.38478 66 -9.75 61.6348 -9.75 56.25C-9.75 50.8652 -5.38478 46.5 0 46.5C5.38478 46.5 9.75 50.8652 9.75 56.25Z"
			stroke="url(#paint0_linear_827_2472)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2472"
			x1="-58.5"
			y1="20.5"
			x2="59.175"
			y2="38.9081"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
