<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M16.1508 28.5823C22.776 28.7558 28.2874 23.5256 28.4609 16.9005C28.6343 10.2754 23.4042 4.76398 16.7791 4.59049C10.1539 4.41701 4.64257 9.64711 4.46908 16.2723C4.29559 22.8974 9.5257 28.4088 16.1508 28.5823ZM13.869 13.8508L10.9939 21.7783L19.0609 19.3219L21.9361 11.3945L13.869 13.8508Z"
		stroke="#8D79EE"
		stroke-width="1.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
