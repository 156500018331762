<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2508"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2508)">
		<path
			d="M-34.3333 65.4167V52.3333C-34.3333 45.1076 -28.4757 39.25 -21.25 39.25H57.25C64.4757 39.25 70.3333 45.1076 70.3333 52.3333V65.4167M-34.3333 65.4167V104.667C-34.3333 111.892 -28.4757 117.75 -21.25 117.75H57.25C64.4757 117.75 70.3333 111.892 70.3333 104.667V65.4167M-34.3333 65.4167H70.3333M-14.7083 98.125H18"
			stroke="url(#paint0_linear_827_2508)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2508"
			x1="-34.3333"
			y1="39.25"
			x2="69.0171"
			y2="60.8064"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
