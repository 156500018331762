<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2390"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2390)">
		<path
			d="M-11.8423 85.0417C-8.93752 96.3275 1.30739 104.667 13.5 104.667C20.7384 104.667 27.2903 101.728 32.0275 96.9777M-11.8423 85.0417C-12.3804 82.9508 -12.6667 80.7588 -12.6667 78.5C-12.6667 76.2412 -12.3804 74.0492 -11.8423 71.9583M-11.8423 85.0417H-19.2083M-11.8423 85.0417H6.95834M-11.8423 71.9583C-8.93752 60.6725 1.30738 52.3333 13.5 52.3333C20.628 52.3333 27.0904 55.1835 31.8096 59.8063M-11.8423 71.9583H-19.2083M-11.8423 71.9583H6.95834M72.375 78.5C72.375 111.016 46.0158 137.375 13.5 137.375C-19.0158 137.375 -45.375 111.016 -45.375 78.5C-45.375 45.9842 -19.0158 19.625 13.5 19.625C46.0158 19.625 72.375 45.9842 72.375 78.5Z"
			stroke="url(#paint0_linear_839_2390)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2390"
			x1="-45.375"
			y1="19.625"
			x2="73.0543"
			y2="38.1511"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
