<script lang="ts">
	import MedalIcon from '$lib/icons/medal-icon.svelte';
	import MagicIcon from '$lib/icons/magic-icon.svelte';
	import BagIcon from '$lib/icons/bag-icon.svelte';
	import CompassIcon from '$lib/icons/compass-icon.svelte';
	import SmileIcon from '$lib/icons/smile-icon.svelte';
	import Card from './Card.svelte';
	import BusFrontIcon from '$lib/icons/bus-front-icon.svelte';
	import LuggageIcon from '$lib/icons/luggage-icon.svelte';
	import Smile_2Icon from '$lib/icons/smile-2-icon.svelte';
	import GroupGradientIcon from '$lib/icons/group-gradient-icon.svelte';
	import ConversationIcon from '$lib/icons/conversation-icon.svelte';
	import CardPurpleIcon from '$lib/icons/card-purple-icon.svelte';
	import { getContext } from 'svelte';

	export let variant: 'offers' | 'benefits' = 'offers';
	let t = getContext('page-contents-landing') as Record<string, string>;

	const DATA = {
		offers: [
			{
				icon: MedalIcon,
				title: t.offerings_professional_drivers,
				description: t.offerings_truly_professional_drivers
			},
			{
				icon: MagicIcon,
				title: t.offerings_unique_booking_solutions,
				description: t.offerings_exclusive_booking_solution
			},
			{
				icon: BagIcon,
				title: t.offerings_child_seats_for_free,
				description: t.offerings_babies_or_children
			},
			{
				icon: CompassIcon,
				title: t.offerings_safe_trip,
				description: t.offerings_your_transfer_operated
			},
			{
				icon: CardPurpleIcon,
				title: t.offerings_pay_later,
				description: t.offerings_book_today_pay_later
			},
			{
				icon: SmileIcon,
				title: t.offerings_comfortable_trip,
				description: t.offerings_super_comfortable_minibuses
			}
		],
		benefits: [
			{
				icon: BusFrontIcon,
				title: t.offerings_spacious_cars,
				description: t.offerings_say_bye_to_cramped_legs
			},
			{
				icon: LuggageIcon,
				title: t.offerings_everything_will_fit,
				description: t.offerings_imagine_all_the_luggage
			},
			{
				icon: Smile_2Icon,
				title: t.offerings_no_intermediaries,
				description: t.offerings_competitive_prices
			},
			{
				icon: GroupGradientIcon,
				title: t.offerings_travel_with_your_company,
				description: t.offerings_with_family_or_group
			},
			{
				icon: ConversationIcon,
				title: t.offerings_communication_with_driver,
				description: t.offerings_direct_communication
			}
		]
	};

	const getRandomNumber = (min: number, max: number) => {
		return Math.random() * (max - min) + min;
	};
</script>

<div class="my-15 mx-auto flex w-full flex-wrap items-center justify-center gap-x-5 gap-y-5">
	{#if variant === 'offers'}
		{#each DATA.offers as offer, index}
			<Card
				title={offer.title}
				description={offer.description}
				icon={offer.icon}
				rotation={(index + 1) % 2 == 0 ? getRandomNumber(0.5, 2.5) : getRandomNumber(-2.5, -0.5)}
			/>
		{/each}
		{#if DATA.offers.length % 2 !== 0}
			<div class="max-w-530px w-full" />
		{/if}
	{:else}
		{#each DATA.benefits as benefit, index}
			<Card
				title={benefit.title}
				description={benefit.description}
				icon={benefit.icon}
				rotation={(index + 1) % 2 == 0 ? getRandomNumber(0.5, 2.5) : getRandomNumber(-2.5, -0.5)}
			/>
		{/each}
		{#if DATA.benefits.length % 2 !== 0}
			<div class="max-w-530px w-full" />
		{/if}
	{/if}
</div>
