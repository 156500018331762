<script lang="ts">
	import StarIcon from '$lib/icons/star-icon.svelte';
	import isDesktop from '$store/isDesktop';
	import type { Review } from '$types/review';
	import { getContext } from 'svelte';
	export let className: string = '';
	const reviews = getContext<Review[]>('reviews');
	let t = getContext('page-contents-landing') as Record<string, string>;
	const averageRating = 4.8;
</script>

<div class={`gap-1.5 ${className}`}>
	<div class="flex-between gap-1.5">
		<span class="karla-medium text-grey-800 text-26px md:text-32px"> {averageRating} </span>
		<span class="flex flex-row items-center justify-start gap-1.5">
			{#each Array(5) as _, index}
				{#if index < 4}
					<StarIcon color="#FFD339" width={$isDesktop ? 32 : 24} height={$isDesktop ? 32 : 24} />
				{:else}
					<StarIcon
						percentage={(averageRating * 100) / 5}
						width={$isDesktop ? 32 : 24}
						height={$isDesktop ? 32 : 24}
						color="#FFD339"
						bgColor="#1e1e1e"
						strokeColor="transparent"
					/>
				{/if}
			{/each}
		</span>
	</div>
	<div class="text-grey-500 karla-medium-base md:karla-medium-2xl">{reviews.length} {t.reviews}</div>
</div>
