<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2481"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="157"
	>
		<rect width="75" height="157" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2481)">
		<path
			d="M24.0417 19.625H-15.2083C-22.4341 19.625 -28.2917 25.4826 -28.2917 32.7083V124.292C-28.2917 131.517 -22.434 137.375 -15.2083 137.375H50.2083C57.4341 137.375 63.2917 131.517 63.2917 124.292V58.875M24.0417 19.625V45.7917C24.0417 53.0174 29.8993 58.875 37.125 58.875H63.2917M24.0417 19.625H25.164C28.6339 19.625 31.9617 21.0034 34.4153 23.457L59.4596 48.5013C61.9132 50.9549 63.2917 54.2827 63.2917 57.7526V58.875"
			stroke="url(#paint0_linear_827_2481)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2481"
			x1="-28.2917"
			y1="19.625"
			x2="64.6975"
			y2="30.9389"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
