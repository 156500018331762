<svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M6.63166 16.0725L6.98056 22.73C7.05764 24.2008 8.3124 25.3306 9.78314 25.2535V25.2535M6.63166 16.0725L6.28275 9.41497C6.20567 7.94422 7.33546 6.68947 8.8062 6.61239L24.7843 5.77502C26.255 5.69794 27.5098 6.82772 27.5868 8.29846L27.9358 14.956M6.63166 16.0725L27.9358 14.956M27.9358 14.956L28.2847 21.6135C28.3617 23.0843 27.232 24.339 25.7612 24.4161V24.4161M21.7667 24.6254L13.7777 25.0441M21.7667 24.6254L21.8714 26.6227C21.9292 27.7258 22.8702 28.5731 23.9733 28.5153V28.5153C25.0764 28.4575 25.9237 27.5164 25.8659 26.4134L25.7612 24.4161M21.7667 24.6254L25.7612 24.4161M13.7777 25.0441L13.8823 27.0414C13.9401 28.1444 13.0928 29.0855 11.9897 29.1433V29.1433C10.8867 29.2011 9.94562 28.3538 9.88781 27.2507L9.78314 25.2535M13.7777 25.0441L9.78314 25.2535M12.167 19.7879L10.8355 19.8577M22.8191 19.2296L24.1506 19.1599"
		stroke="url(#paint0_linear_813_8051)"
		stroke-width="1.17073"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_813_8051"
			x1="6.14319"
			y1="6.75195"
			x2="29.049"
			y2="9.67753"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#BEB2F7" />
			<stop offset="0.958333" stop-color="#8D79EE" />
		</linearGradient>
	</defs>
</svg>
