<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_827_2499"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_827_2499)">
		<path
			d="M30.125 58.875H30.1838M-9.125 58.875H-9.06625M69.375 78.5C69.375 111.016 43.0158 137.375 10.5 137.375C-22.0158 137.375 -48.375 111.016 -48.375 78.5C-48.375 45.9842 -22.0158 19.625 10.5 19.625C43.0158 19.625 69.375 45.9842 69.375 78.5ZM10.5 104.667C6.88714 104.667 3.95833 101.738 3.95833 98.125C3.95833 94.5121 6.88714 91.5833 10.5 91.5833C14.1129 91.5833 17.0417 94.5121 17.0417 98.125C17.0417 101.738 14.1129 104.667 10.5 104.667Z"
			stroke="url(#paint0_linear_827_2499)"
			stroke-width="3"
			stroke-linecap="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_827_2499"
			x1="-48.375"
			y1="19.625"
			x2="70.0543"
			y2="38.1511"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
