<script lang="ts">
	import type { SvelteComponent } from 'svelte';
	import { fade, scale } from 'svelte/transition';
	export let title: string;
	export let description: string;
	export let rotation = 0;
	export let className = '';
	export let icon: typeof SvelteComponent<any>;
</script>

<div
	class={`max-w-530px rounded-4 min-h-220px p-24px max-h-[330px] w-full transform bg-white shadow-lg ${className}}`}
	style={`rotate:${rotation}deg;`}
	in:scale|global={{ duration: 400, start: 0.3, opacity: 0 }}
>
	<div class="mb-6">
		<svelte:component this={icon} />
	</div>
	<h3 class="font-karla font-600 text-26px text-grey-800 mb-3">
		{title}
	</h3>
	<p class="rubik-regular-base">
		{description}
	</p>
</div>
