<script lang="ts">
	import StarIcon from '$lib/icons/star-icon.svelte';
	import type { Review } from '$types/review';
	import GoogleIcon from '$lib/icons/google-icon.svelte';
	import TripadvisorIcon from '$lib/icons/tripadvisor-icon.svelte';
	import TrustpilotIcon from '$lib/icons/trustpilot-icon.svelte';
	import type { SvelteComponent } from 'svelte';
	import Avatar from './Avatar.svelte';
	import { getRelativeTime } from '$utils/relativeTime';
	import { createEventDispatcher } from 'svelte';
	export let review: Review;

	const dispatch = createEventDispatcher();

	const PLATFORM_ICONS: { [key: string]: typeof SvelteComponent<any> } = {
		google: GoogleIcon,
		tripadvisor: TripadvisorIcon,
		trustpilot: TrustpilotIcon
	};

	const handleModalOpen = () => {
		dispatch('open', {
			review
		});
	};
</script>

<div class="max-w-700px h-227px rounded-5px relative w-full bg-white p-4">
	<button
		class="absolute right-4 top-4 text-gray-500 hover:text-gray-800 dark:text-white dark:hover:text-white"
		on:click={handleModalOpen}
		aria-label="View review"
	>
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.87671 0.16857C1.52713 0.27591 1.19384 0.493249 0.847459 0.839523C-0.029982 1.71707 0.000124149 1.39175 0.000124149 9.99488C0.000124149 18.5963 -0.0297693 18.2729 0.846076 19.1489C1.70596 20.0086 1.58182 19.9948 8.44794 19.9948H14.4104L17.2052 17.2L20 14.4053V8.44277C20 1.57665 20.0138 1.70079 19.154 0.840906C18.2767 -0.0364286 18.6087 -0.00610968 9.9541 0.0024009C3.56723 0.00857107 2.31351 0.034422 1.87671 0.16857ZM17.8805 2.11441L18.1915 2.42536V8.01862V13.6119H16.6987C14.7371 13.6119 14.2419 13.8039 13.8172 14.7289C13.657 15.0776 13.6213 15.4281 13.6194 16.6704L13.6171 18.1863H8.02379H2.43053L2.11958 17.8754L1.80862 17.5644V9.99488V2.42536L2.11958 2.11441L2.43053 1.80345H10.0001H17.5696L17.8805 2.11441ZM4.83573 6.63906C4.67594 6.79874 4.57456 7.02246 4.57456 7.21544C4.57456 8.09862 4.82477 8.18639 7.34337 8.18639H9.37814L9.6891 7.87543C10.0961 7.46841 10.1073 6.99905 9.71857 6.63874C9.43889 6.37959 9.42304 6.37789 7.26699 6.37789C5.10466 6.37789 5.09583 6.37885 4.83573 6.63906ZM12.2728 6.50236C12.0225 6.62353 11.8086 7.01342 11.8086 7.34852C11.8086 7.46724 11.9484 7.70437 12.1195 7.87543C12.4242 8.18011 12.4533 8.18639 13.561 8.18639C14.7341 8.18639 15.2245 8.05458 15.3595 7.70288C15.5015 7.33278 15.4214 6.89597 15.1644 6.63906C14.9169 6.39161 14.8403 6.37831 13.7016 6.38512C13.0408 6.38906 12.3978 6.44182 12.2728 6.50236ZM5.05328 10.1178C4.50318 10.4186 4.39839 11.105 4.83573 11.5422L5.09679 11.8034H8.98985C13.0763 11.8034 13.3777 11.7715 13.551 11.3199C13.6357 11.0992 13.6357 10.699 13.551 10.4784C13.378 10.0275 13.0721 9.99467 9.07453 9.99808C6.52402 10.0003 5.19573 10.0398 5.05328 10.1178Z"
				fill="#4B5563"
			/>
		</svg>
	</button>
	<div class="flex flex-row justify-between">
		<div class="flex flex-row flex-nowrap items-center gap-x-2">
			{#if review.avatarUrl}
				<img src={review.avatarUrl} alt="user icon" class="h-10 w-10 rounded-full" loading="lazy" />
			{:else}
				<div
					class="bg-grey-200 dark:text-grey-800 flex h-10 w-10 items-center justify-center rounded-full p-1 text-xl"
				>
					<Avatar username={review.name} />
				</div>
			{/if}
			<div class="rubik-base flex flex-col leading-5 dark:text-white">
				<p>{review.name}</p>
				<div class="flex flex-row items-center gap-x-1">
					{#each Array(5) as _, index (index)}
						{#if index < review.rating}
							<StarIcon color="#FFD339" />
						{:else if review.rating % 1 !== 0 && index === Math.floor(review.rating + 1)}
							<StarIcon percentage={(review.rating % 1) * 100} color="#FFD339" />
						{:else}
							<StarIcon color="transparent" />
						{/if}
					{/each}
				</div>
			</div>
		</div>
	</div>
	<div class="rubik-regular mt-5 line-clamp-4 text-base dark:text-white">
		{review.review}
	</div>
	<div
		class="absolute bottom-4 flex w-[calc(100%-40px)] flex-row flex-nowrap items-center justify-between text-xs text-gray-500"
	>
		<p>{getRelativeTime(review.date ? new Date(review.date) : new Date())}</p>
		<a
			href={review.url}
			target="_blank"
			rel="noopener noreferrer"
			aria-label="Go to author"
			class="w-56px h-36px bg-grey-100 flex-center rounded-15px"
		>
			{#if review.platform && PLATFORM_ICONS[review.platform]}
				<svelte:component this={PLATFORM_ICONS[review.platform]} />
			{/if}
		</a>
	</div>
</div>
