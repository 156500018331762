<script lang="ts">
	import FaqCollapse from './FaqCollapse.svelte';
	import LocatedIcon from '$lib/icons/faqs/landing/located-icon.svelte';
	import FileIcon from '$lib/icons/faqs/landing/file-icon.svelte';
	import CalenderIcon from '$lib/icons/faqs/landing/calender-icon.svelte';
	import BillDiagonalIcon from '$lib/icons/faqs/landing/bill-diagonal-icon.svelte';
	import BillIcon from '$lib/icons/faqs/landing/bill-icon.svelte';
	import BusIcon from '$lib/icons/faqs/landing/bus-icon.svelte';
	import CardIcon from '$lib/icons/faqs/landing/card-icon.svelte';
	import EurocoinIcon from '$lib/icons/faqs/landing/eurocoin-icon.svelte';
	import GroupIcon from '$lib/icons/faqs/landing/group-icon.svelte';
	import LocateIcon from '$lib/icons/faqs/landing/locate-icon.svelte';
	import SurprisedIcon from '$lib/icons/faqs/landing/surprised-icon.svelte';
	import TicketIcon from '$lib/icons/faqs/landing/ticket-icon.svelte';
	import { getContext } from 'svelte';
	let t = getContext('page-contents-faq') as Record<string, string>;

	const FAQ = [
		{
			icon: LocatedIcon,
			question: t.meetDriverQuestion,
			answer: t.meetDriverAnswer
		},
		{
			icon: FileIcon,
			question: t.reservationTimeQuestion,
			answer: t.reservationTimeAnswer
		},
		{
			icon: CalenderIcon,
			question: t.idOrReservationDetailsQuestion,
			answer: t.idOrReservationDetailsAnswer
		},
		{
			icon: LocateIcon,
			question: t.noShowQuestion,
			answer: t.noShowAnswer
		},
		{
			icon: TicketIcon,
			question: t.cancellationFeeQuestion,
			answer: t.cancellationFeeAnswer
		},
		{
			icon: GroupIcon,
			question: t.maxPassengersQuestion,
			answer: t.maxPassengersAnswer
		},
		{
			icon: SurprisedIcon,
			question: t.travelWithKidsQuestion,
			answer: t.travelWithKidsAnswer
		},
		{
			icon: BillDiagonalIcon,
			question: t.childrenPayQuestion,
			answer: t.childrenPayAnswer
		},
		{
			icon: CardIcon,
			question: t.paymentMethodsQuestion,
			answer: t.paymentMethodsAnswer
		},
		{
			icon: EurocoinIcon,
			question: t.howCanIPayQuestion,
			answer: t.howCanIPayAnswer
		},
		{
			icon: BusIcon,
			question: t.changeOrCancelQuestion,
			answer: t.changeOrCancelAnswer
		},
		{
			icon: BillIcon,
			question: t.cashAcceptedQuestion,
			answer: t.cashAcceptedAnswer
		}
	];
</script>

<div class="grid place-items-center items-start gap-x-4 md:grid-cols-2">
	{#each FAQ as faq}
		<FaqCollapse {...faq} />
	{/each}
</div>
