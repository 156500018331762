<svg width="75" height="156" viewBox="0 0 75 156" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask
		id="mask0_839_2381"
		style="mask-type:alpha"
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="75"
		height="156"
	>
		<rect width="75" height="156" fill="#D9D9D9" />
	</mask>
	<g mask="url(#mask0_839_2381)">
		<path
			d="M18.5 124.292L14.668 128.124C9.55862 133.233 1.27471 133.233 -3.83465 128.124L-7.66667 124.292M18.5 124.292L64.2917 78.5M18.5 124.292L14.668 120.46C9.55862 115.35 1.27471 115.35 -3.83465 120.46L-7.66667 124.292M-7.66667 124.292L-27.2917 104.667M-27.2917 104.667L-31.1237 100.835C-36.233 95.7253 -36.233 87.4414 -31.1237 82.332L-27.2917 78.5M-27.2917 104.667L-23.4596 100.835C-18.3503 95.7253 -18.3503 87.4414 -23.4596 82.332L-27.2917 78.5M-27.2917 78.5L18.5 32.7083M18.5 32.7083L22.332 28.8763C27.4414 23.767 35.7253 23.767 40.8346 28.8763L44.6667 32.7083M18.5 32.7083L22.332 36.5404C27.4414 41.6497 35.7253 41.6497 40.8346 36.5404L44.6667 32.7083M44.6667 32.7083L64.2917 52.3333M64.2917 52.3333L68.1237 56.1654C73.233 61.2747 73.233 69.5586 68.1237 74.668L64.2917 78.5M64.2917 52.3333L60.4596 56.1654C55.3503 61.2747 55.3503 69.5586 60.4596 74.668L64.2917 78.5M31.5833 78.5C31.5833 85.7257 25.7257 91.5833 18.5 91.5833C11.2743 91.5833 5.41667 85.7257 5.41667 78.5C5.41667 71.2743 11.2743 65.4167 18.5 65.4167C25.7257 65.4167 31.5833 71.2743 31.5833 78.5Z"
			stroke="url(#paint0_linear_839_2381)"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
	<defs>
		<linearGradient
			id="paint0_linear_839_2381"
			x1="-40.375"
			y1="19.625"
			x2="78.0543"
			y2="38.1511"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
