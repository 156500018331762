<script lang="ts">
	import ArrowLongIcon from '$lib/icons/arrow-long-icon.svelte';
	import ClockIcon from '$lib/icons/clock-icon.svelte';
	import LocationIcon from '$lib/icons/pickup-details/location-icon.svelte';
	import { locationSelection } from '$store/locationSelection';
	import type { PopularDestination } from '$types/popularDestinations.types';
	import { LINK_BOOKING, LOCALIZELINK } from '$utils/link';
	import { getContext } from 'svelte';
	import type { LANGUAGE } from '$lib/constants/locale';
	import { getPopularDestinationRoute } from '$utils/popularDestination';
	export let data: PopularDestination;
	export let isHidden = false;
	let lang = getContext('lang') as LANGUAGE;
	let t = getContext('page-contents-landing') as Record<string, string>;
	let link = getPopularDestinationRoute(lang, data.bookingHashId);
	const handleBooking = () => {
		$locationSelection.pickup!.place_id = data.from.placeId;
		$locationSelection.dropoff!.place_id = data.to.placeId;
		$locationSelection.isValid = true;
	};
</script>

{#if !isHidden}
	<div class={`max-w-275px card min-w-226px hidden w-full lg:block`}>
		<div class="rounded-3 bg-white px-6 py-5">
			<div class="flex-center rubik-regular text-18px gap-3" style="letter-spacing: -0.5px;">
				<span class="line-clamp-2 w-full" title={data.from.name}>{data.from.name}</span>
				<ArrowLongIcon />
				<span class="line-clamp-2 w-full" title={data.to.name}>
					{data.to.name}
				</span>
			</div>
		</div>
		<div class="rounded-3 h-55.5 relative grid place-content-center overflow-clip bg-white pb-5">
			<div
				class="h-48px flex-center absolute top-0 w-full gap-2"
				style="background: linear-gradient(90deg, #F8F8FF 0%, #D7DBFF 50%, #F8F8FF 100%);"
			>
				<ClockIcon width={24} height={24} />
				{data.duration}
			</div>
			<div class="flex-col-center">
				<span class="karla-medium-base">{t.popular_destinations_starting}</span>
				<span class="karla-bold text-32px"> {data.price}€ </span>
			</div>
			<a
				class=" bg-linear-purple-100 hover:bg-linear-purple-200 rounded-5px rubik-regular flex-center h-50px text-18px w-198px absolute bottom-5 left-1/2 -translate-x-1/2 transform text-white"
				on:click={handleBooking}
				data-sveltekit-preload-code
				href={`${LOCALIZELINK(lang, LINK_BOOKING)}/${link}`}
			>
				{t.popular_destinations_book_your_trip_now}
			</a>
		</div>
	</div>
	<div class="h-238px flex-center card w-screen-without-scroll flex flex-row lg:hidden">
		<div
			class="min-w-75px flex-col-center rounded-3 h-full gap-8"
			style="background: linear-gradient(0deg, #FFF 0%, #D7DBFF 51.04%, #FFF 100%);"
		>
			<span class="flex-col-center rubik-regular text-18px gap-2">
				<ClockIcon width={24} height={24} />
				{data.duration}
			</span>
			<span class="flex-col-center rubik-regular text-18px gap-2">
				<LocationIcon variant="solid" />
				{data.distance} km
			</span>
		</div>
		<div
			class="max-w-278px rounded-3 min-h-238px grid h-full w-full place-content-center bg-white p-5"
		>
			<div class="flex-center rubik-regular text-18px mb-5 gap-3" style="letter-spacing: -0.5px;">
				<span class="w-full" title={data.from.name}>{data.from.name}</span>
				<ArrowLongIcon />
				<span class="w-full" title={data.to.name}>
					{data.to.name}
				</span>
			</div>
			<div class="flex-col-center mb-4">
				<span class="karla-medium-base"> {t.popular_destinations_starting} </span>
				<span class="karla-bold text-32px"> {data.price}€ </span>
			</div>

			<a
				class=" bg-linear-purple-100 hover:bg-linear-purple-200 rounded-5px rubik-regular flex-center h-50px text-18px w-198px mx-auto text-white"
				href={`${LOCALIZELINK(lang, LINK_BOOKING)}/${link}`}
				data-sveltekit-preload-code
				on:click={handleBooking}
			>
				{t.popular_destinations_book_your_trip_now}
			</a>
		</div>
	</div>
{/if}

<style type="postcss">
	.card {
		@apply shadow-grey-200 shadow-xl;
	}
</style>
